tenderView.controller('tenderViewPreQualificationModalCtrl', function(
    $scope,
    $rootScope,
    $timeout,
    $uibModalInstance,
    data,
    md5,
    uuid4,
    ntFlash,
    qualificationService,
    documentsService,
    tenderRejectReasonsListService
) {
    $scope.tenderId = data.tenderId;
    $scope.procurementMethodType = data.procurementMethodType;
    $scope.procurementMethodRationale = data.procurementMethodRationale;
    $scope.isApproval = data.isApproval;
    $scope.qualificationId = data.qualificationId;
    $scope.criterias = data.criterias &&
                       ['aboveThresholdEU', 'competitiveDialogueUA', 'competitiveDialogueEU',
                        'competitiveDialogueEU.stage2', 'esco', 'closeFrameworkAgreementUA'
                       ].includes($scope.procurementMethodType) ? data.criterias : [];

    $scope.upload = {
        filesUploaded: data.documents || [],
        files: [],
        eligibilityFiles: []
    };

    $scope.agree = {
        eligible: false,
        qualified: false,
        status: 'active'
    };

    $scope.reject = {
        title: '',
        description: '',
        status: 'unsuccessful'
    };

    $scope.rejectReasons = tenderRejectReasonsListService.getRejectReasonsList($scope.procurementMethodType, $scope.procurementMethodRationale);

    let uploadedDocuments = $scope.upload.filesUploaded.filter(d => d.author !== 'bots' && d.title !== 'sign.p7s' && d.format !== 'application/pkcs7-signature');
    $scope.isFilesAlreadyUploaded = uploadedDocuments.length > 0;

    if ($scope.criterias && $scope.criterias.length) {
        $scope.criterias.forEach(criteria => {
            criteria.activate = false;
            criteria.requirementGroups.forEach(group => {
                group.requirements.forEach(requirement => {
                    requirement.requirementResponse = {
                        id: md5.createHash(uuid4.generate()),
                        requirement: {
                            id: requirement.id,
                            title: requirement.title
                        },
                        value: false,
                        evidences: []
                    };
                    (requirement.eligibleEvidences || []).forEach(evidence => {
                        requirement.requirementResponse.evidences.push({
                            id: md5.createHash(uuid4.generate()),
                            type: evidence.type,
                            description: '',
                            relatedDocument: {
                                id: '',
                                title: ''
                            }
                        });
                    });
                });
            });
        });
    }

    $scope.collectRejectReasons = function() {
        $scope.reject.title = '';
        $scope.rejectReasons.forEach(function(reason) {
            if (reason.checked) {
                if ($scope.reject.title.length) {
                    $scope.reject.title = $scope.reject.title + '; ' + reason.title;
                } else {
                    $scope.reject.title = reason.title;
                }
            }
        });
    };

    $scope.submit = function() {
        const data = $scope.isApproval ? $scope.agree : $scope.reject;
        if (data.reject && data.reject.title) {
            data.reject.title = data.reject.title.toString();
        }
        data.files = $scope.upload.files;

        if ($scope.isCriteriaActivated()) {
            const requirementResponses = [];
            $scope.criterias.forEach(criteria => {
                if (criteria.activate) {
                    criteria.requirementGroups.forEach(group => {
                        group.requirements.forEach(requirement => {
                            (requirement.requirementResponse.evidences || []).forEach(evidence => {
                                if (evidence.type === 'statement') delete evidence.relatedDocument;
                            });
                            requirementResponses.push(requirement.requirementResponse);
                        });
                    });
                }
            });
            data.requirementResponses = requirementResponses;
        }

        $uibModalInstance.close(data);

    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.deleteDoc = function(index, isEligibility) {
        if (isEligibility)
            $scope.upload.eligibilityFiles.splice(index, 1);
        else
            $scope.upload.files.splice(index, 1);
    };

    $scope.$watch('upload.filesSelected', function(val) {
        if (val && val.length) {
            $scope.upload.files = $scope.upload.files.concat(val);
        }
    });

    $scope.isCriteriaActivated = function() {
        if ($scope.criterias && $scope.criterias.length)
            return $scope.criterias.some(el => el.activate === true);
        return false;
    }

    $scope.uploadEligibilityDoc = function() {
        documentsService.save($scope.upload.eligibilityFiles[0], $scope.tenderId, { qualificationId: $scope.qualificationId })
            .then(data => {
                console.log('documentsService data', data);
                if (data.status === 200) {
                    ntFlash.success(gettext('Документ завантажено до документ-сервісу.'));
                    $scope.upload.filesUploaded.push(data.data.data);
                    $scope.upload.eligibilityFiles = [];
                }
            })
            .catch(error => {
                ntFlash.error(gettext('Під час завантаження документу сталася помилка.'));
                console.log('documentsService save', error);
            });
    }

});