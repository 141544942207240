class FormationService {
    constructor() {
        this.address = (data) => {
            let address = ':postalCode:countryName:region:locality:streetAddress';

            let addressFields = {
                "countryName": "",
                "postalCode": "",
                "region": "",
                "locality": "",
                "streetAddress": ""
            };

            data = angular.extend(addressFields, data);

            for (var key in data) {
                if (key !== 'field') {
                    let field = new RegExp(':' + key, 'g');
                    address = address.replace(field, data[key] != "" ? data[key] + ', ' : '');
                }
            }
            return address;
        };
        this.classifier = (data) => {
            let resultClassifier = "";

            if (data) {
                data.forEach(function (classifier) {
                    resultClassifier += classifier.id + ' - ' + classifier.description + '; ';
                });
            }

            return resultClassifier;
        };
        this.tender = (data) => {
            console.log(data)
            let resultData = data;


            if(data.tenderPeriod) {
                resultData.tempTenderPeriod = {
                    startDate: (data.tenderPeriod.startDate == null) ? null : data.tenderPeriod.startDate,
                    endDate: (data.tenderPeriod.endDate == null) ? null : data.tenderPeriod.endDate
                }
            }


            if (data.procurementMethodType === 'esco') {

                resultData.NBUdiscountRate = +Number(data.NBUdiscountRate * 100).toFixed(3);
                resultData.minimalStepPercentage = +Number(data.minimalStepPercentage * 100).toFixed(3);
                for (let i in data.lots) {
                    if (data.lots[i].minimalStepPercentage) {
                        resultData.lots[i].minimalStepPercentage = +Number(data.lots[i].minimalStepPercentage * 100).toFixed(3);
                        resultData.lots[i].yearlyPaymentsPercentageRange = +Number(data.lots[i].yearlyPaymentsPercentageRange * 100).toFixed(3);
                    }
                }
            } else {
                if(data.value){
                    resultData.value.amount = Number(data.value.amount);

                } else if(data.procurementMethodType !== 'closeFrameworkAgreementSelectionUA'){
                    resultData.value = {
                        currency: data.items[0].unit.value.currency,
                        valueAddedTaxIncluded: data.items[0].unit.value.valueAddedTaxIncluded
                    }
                }
            }

            if (resultData.procurementMethod !== 'limited' &&
                resultData.procurementMethodType !== 'reporting' &&
                resultData.procurementMethodType !== 'closeFrameworkAgreementSelectionUA'
            ) {
                if (resultData.procurementMethodType !== 'esco' && data.minimalStep) {
                    resultData.minimalStep.amount = Number(data.minimalStep.amount)
                }
                if (!data.enquiryPeriod) resultData.enquiryPeriod = {};

                resultData.tenderPeriod.startDateIsOpen = false;
                resultData.tenderPeriod.endDateIsOpen = false;
                resultData.enquiryPeriod.startDateIsOpen = false;
                resultData.enquiryPeriod.endDateIsOpen = false;

                if (data.tenderPeriod.startDate && data.tenderPeriod.endDate) {
                    resultData.tenderPeriod.startDate = new Date(data.tenderPeriod.startDate);
                    resultData.tenderPeriod.endDate = new Date(data.tenderPeriod.endDate);
                }

                if (data.enquiryPeriod.startDate && data.enquiryPeriod.endDate) {
                    resultData.enquiryPeriod.startDate = new Date(data.enquiryPeriod.startDate);
                    resultData.enquiryPeriod.endDate = new Date(data.enquiryPeriod.endDate);
                }

            }

            for (let i = 0; data.items && i < data.items.length; i++) {
                if (data.items[i].quantity) {
                    resultData.items[i].quantity = Number(data.items[i].quantity);
                }
            }

            for (let i in data.items) {
                if (!resultData.items[i].deliveryDate) {
                    resultData.items[i].deliveryDate = {};
                }

                resultData.items[i].deliveryDate.startDateIsOpen = false;
                resultData.items[i].deliveryDate.startDate = !data.items[i].deliveryDate.startDate || data.items[i].deliveryDate.startDate === null ? null : new Date(data.items[i].deliveryDate.startDate);
                resultData.items[i].deliveryDate.endDateIsOpen = false;
                resultData.items[i].deliveryDate.endDate = !data.items[i].deliveryDate.endDate || data.items[i].deliveryDate.endDate === null ? null : new Date(data.items[i].deliveryDate.endDate);

                // resultData.items[i].classification.field = data.items[i].classification.id + ' - ' + data.items[i].classification.description;
                // resultData.items[i].classification.data = data.items[i].classification;

                // if (data.items[i].additionalClassifications) {
                //     resultData.items[i].additionalClassifications[0].data = [];
                //     resultData.items[i].additionalClassifications[0].data.push({
                //         id : data.items[i].additionalClassifications[0].id,
                //         description : data.items[i].additionalClassifications[0].description,
                //         scheme : data.items[i].additionalClassifications[0].scheme
                //     });
                //     resultData.items[i].additionalClassifications[0].field = this.classifier(data.items[i].additionalClassifications[0].data);
                //
                //     if (data.items[i].additionalClassifications[1] && data.items[i].additionalClassifications[1].scheme == "ATC") {
                //         resultData.items[i].additionalClassifications[1].data = [];
                //         resultData.items[i].additionalClassifications[1].data.push({
                //             id : data.items[i].additionalClassifications[1].id,
                //             description : data.items[i].additionalClassifications[1].description,
                //             scheme : data.items[i].additionalClassifications[1].scheme
                //         });
                //         resultData.items[i].additionalClassifications[1].field = this.classifier(data.items[i].additionalClassifications[1].data);
                //     }
                // } else {
                //     resultData.items[i].additionalClassifications = [];
                // }

                // if (resultData.items[i].deliveryAddress) {
                //     resultData.items[i].deliveryAddress.field = this.address(data.items[i].deliveryAddress);
                // } else {
                //     resultData.items[i].deliveryAddress = {};
                // }
            }

            //TODO: Put all of those data manipulation to service
            // if (data.features) {
            //     let resultString = '';
            //     data.features.data = [];
            //
            //     if (data.features[0]) {
            //         data.features.forEach((val) => {
            //             resultString += val.title + ' (' + val.description + ') ';
            //             val.enum.forEach((option) => {
            //                 option.value = Number((Number(option.value) * 100).toFixed());
            //             });
            //             data.features.data.push(val);
            //         });
            //     }
            //
            //     data.features.field = resultString;
            // } else {
            //     data.features = {
            //         field: "",
            //         data: []
            //     };
            // }
            // if (data.lots) {
            //     var resultString = '';
            //     data.lots.data = [];
            //
            //     if (data.lots[0] != undefined) {
            //         data.lots.forEach((val) => {
            //             resultString += val.title + '; ';
            //             data.lots.data.push(val);
            //         });
            //     }
            //
            //     data.lots.field = resultString;
            // }

            return resultData;
        };
        this.contract = (data) => {
            let resultData = data;

            if (data.value) {
                resultData.value.amount = Number(data.value.amount);
            } else {
                resultData.value = {
                    amount: ''
                };
            }

            resultData.period.startDateIsOpen = false;
            resultData.period.startDate = data.period.startDate == null ? null : new Date(data.period.startDate);
            resultData.period.endDateIsOpen = false;
            resultData.period.endDate = new Date(data.period.endDate);

            resultData.items[0].quantity = Number(data.items[0].quantity);

            for (let i in data.items) {
                if (!resultData.items[i].deliveryDate) {
                    resultData.items[i].deliveryDate = {};
                }

                resultData.items[i].deliveryDate.startDateIsOpen = false;
                resultData.items[i].deliveryDate.startDate = data.items[i].deliveryDate.startDate == null ? null : new Date(data.items[i].deliveryDate.startDate);
                resultData.items[i].deliveryDate.endDateIsOpen = false;
                resultData.items[i].deliveryDate.endDate = data.items[i].deliveryDate.endDate == null ? null : new Date(data.items[i].deliveryDate.endDate);
                resultData.items[i].classification.field = data.items[i].classification.id + ' - ' + data.items[i].classification.description;
                resultData.items[i].classification.data = data.items[i].classification;
                if (data.items[i].additionalClassifications) {
                    resultData.items[i].additionalClassifications.data = data.items[i].additionalClassifications;
                    resultData.items[i].additionalClassifications.field = '';
                    resultData.items[i].additionalClassifications.field = this.classifier(data.items[i].additionalClassifications);
                } else {
                    resultData.items[i].additionalClassifications = [];
                }

                if (resultData.items[i].deliveryAddress) {
                    resultData.items[i].deliveryAddress.field = this.address(data.items[i].deliveryAddress);
                } else {
                    resultData.items[i].deliveryAddress = {};
                }
            }

            return resultData;
        };
    }

    setSignToItem(item) {
        item.isSigned = false;
        if (item.documents) {
            item.documents.forEach(function (document) {
                if (document.title === 'sign.p7s' && document.format === 'application/pkcs7-signature') {
                    item.isSigned = true;
                    item.dateSigned = document.dateModified;
                }
            });
        }
    }
}
commons.service('formationService', FormationService);
