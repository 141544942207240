class TenderStatesListService {
    constructor() {

        //var alowedMethodTypes = OPC_CONFIG.OPC_CREATE_PROCUREMENT_METHOD_TYPE;
        this.getTenderOwners = () => {
            return  [
                {
                    value: 'all',
                    title: gettext('Во всех закупках')
                },
                {
                    value: 'user-tenders',
                    title: gettext('В созданных мной закупках')
                },
                {
                    value: 'user-bids',
                    title: gettext('В закупках с моим участием')
                },
                {
                    value: 'company-tenders',
                    title: gettext('В созданных компанией закупках')
                },
                {
                    value: 'company-bids',
                    title: gettext('В закупках с участием компании')
                }
            ];
        };
        this.getStatusesList = () => {
            return [
                {
                    title: gettext("Неактивоване запрошення"),
                    value: "draft.pending"
                },
                {
                    title: gettext("Неуспішна закупівля"),
                    value: "draft.unsuccessful"
                },
                // {
                //     title: gettext("Період запрошення"),
                //     value: "active.enquiries"
                // },
                {
                    title: gettext("Уточнения"),
                    value: "active.enquiries"
                },
                {
                    title: gettext("Предложения"),
                    value: "active.tendering"
                },
                {
                    title: gettext("Преквалификация"),
                    value: "active.pre-qualification"
                },
                {
                    title: gettext("Прекваліфікація (період оскарження)"),
                    value: "active.pre-qualification.stand-still"
                },
                {
                    title: gettext("Аукцион"),
                    value: "active.auction"
                },
                {
                    title: gettext("Квалификация"),
                    value: "active.qualification"
                },
                {
                    title: gettext("Кваліфікація переможців (період оскарження)"),
                    value: "active.qualification.stand-still"
                },
                {
                    title: gettext("Активная закупка"),
                    value: "active"
                },
                {
                    title: gettext("Рассмотрен"),
                    value: "active.awarded"
                },
                {
                    title: gettext("Отменен"),
                    value: "cancelled"
                },
                {
                    title: gettext("Завершен"),
                    value: "complete"
                },
                {
                    title: gettext("Несостоявшийся"),
                    value: "unsuccessful"
                }/*,
                {
                    title: gettext("Мой черновик"),
                    value: "local_draft"
                }*/
            ];
        };
        this.getProcurementMethodTypesList = () => {
            let list = [
                {
                    title: gettext("Все процедуры"),
                    value: "allProcurementMethodTypes"
                },
                {
                    title: gettext("Допороговые закупки"),
                    value: "belowThreshold"
                },
                {
                    title: gettext("Запит цінової пропозиції"),
                    value: "priceQuotation"
                },
                {
                    title: gettext("Відкриті торги з особливостями"),
                    value: "aboveThreshold"
                },
                {
                    title: gettext("Тендрер"),
                    value: "competitiveOrdering"
                },
                {
                    title: gettext("Открытые торги"),
                    value: "aboveThresholdUA"
                },
                {
                    title: gettext("Открытые торги с публикацией на английском языке"),
                    value: "aboveThresholdEU"
                },
                {
                    title: gettext("Отчет о заключенном договоре"),
                    value: "reporting"
                },
                {
                    title: gettext("Переговорная процедура"),
                    value: "negotiation"
                },
                {
                    title: gettext("Переговорная процедура (сокращенная)"),
                    value: "negotiation.quick"
                },
                {
                    title: gettext("Открытые торги для закупки энергосервиса"),
                    value: "esco"
                },
                {
                    title: gettext("Конкурентный диалог 1-ый этап"),
                    value: "competitiveDialogueUA"
                },
                {
                    title: gettext("Конкурентный диалог 2-ой этап"),
                    value: "competitiveDialogueUA.stage2"
                },
                {
                    title: gettext("Конкурентный диалог с публикацией на английском языке 1-ый этап"),
                    value: "competitiveDialogueEU"
                },
                {
                    title: gettext("Конкурентный диалог с публикацией на английском языке 2-ой этап"),
                    value: "competitiveDialogueEU.stage2"
                },
                {
                    title: gettext("Переговорная процедура для нужд обороны"),
                    value: "aboveThresholdUA.defense"
                },
                {
                    title: gettext("Упрощенные торги с применением электронной системы закупок"),
                    value: "simple.defense"
                },
                {
                    title: gettext("Укладання рамкової угоди"),
                    value: "closeFrameworkAgreementUA"
                },
                {
                    title: gettext("Отбор для закупки по рамочному соглашению"),
                    value: "closeFrameworkAgreementSelectionUA"
                },
                // ,{
                //     title: gettext("Открытые торги для закупки энергосервиса. Переговорная процедура"),
                //     value: "esco.negotiation"
                // }
            ];

            return list;
        };
    }
}
commons.service('tenderStatesListService', TenderStatesListService);
