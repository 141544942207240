tenderView.controller('tenderViewBidsModalCtrl', function (
    $rootScope,
    $scope,
    $uibModalInstance,
    $uibModal,
    modalData,
    md5,
    uuid4,
    documentsService,
    tenderRejectReasonsListService,
    $stateParams,
    awardsService,
    $state,
    ntFlash
) {
    var tenderId = $stateParams.id;
    $scope.files = [];
    $scope.isAccepted = modalData.isAccepted;
    $scope.requestInProgress = false;
    $scope.uploadedFiles = 0;
    $scope.awardStatus = modalData.awardStatus;
    $scope.tenderCdbId = modalData.tenderCdbId;
    $scope.bidId = modalData.bidId;
    $scope.isCancelable = modalData.isCancelable;
    $scope.procurementMethodType = modalData.procurementMethodType;
    $scope.procurementMethodRationale = modalData.procurementMethodRationale;

    $scope.criterias = modalData.criterias &&
                       ['aboveThresholdUA',
                           'aboveThreshold',
                           'competitiveOrdering',
                        'competitiveDialogueUA.stage2'
                       ].includes($scope.procurementMethodType) ? modalData.criterias : [];

    $scope.documents = [];
    awardsService.getAward(tenderId, modalData.awardId)
        .then(award => {
            $scope.documents = award.data.response.documents;
        });

    $scope.awardSign = {
        signed: false
    };

    $scope.agree = {
        eligible: false,
        qualified: false,
        status: modalData.status
    };

    $scope.reject = {
        title: "",
        description: "",
        status: modalData.status
    };

    if (modalData.isAccepted) {
        $scope.modalHeader = gettext('Выбор победителя');
        $scope.uploadHeader = gettext('Загрузите протокол квалификации');
        $scope.cancelBtn = gettext('Назад');
        $scope.acceptBtn = gettext('Выбрать');
    } else {
        $scope.modalHeader = gettext('Отказ');
        $scope.uploadHeader = gettext('Укажите причину отказа');
        $scope.cancelBtn = gettext('Назад');
        $scope.acceptBtn = gettext('Отказать');
    }

    $scope.rejectReasons = tenderRejectReasonsListService.getRejectReasonsList($scope.procurementMethodType, $scope.procurementMethodRationale);

    if ($scope.criterias && $scope.criterias.length) {
        $scope.criterias.forEach(criteria => {
            criteria.activate = false;
            criteria.requirementGroups.forEach(group => {
                group.requirements.forEach(requirement => {
                    requirement.requirementResponse = {
                        id: md5.createHash(uuid4.generate()),
                        requirement: {
                            id: requirement.id,
                            title: requirement.title
                        },
                        value: false,
                        evidences: []
                    };
                    (requirement.eligibleEvidences || []).forEach(evidence => {
                        requirement.requirementResponse.evidences.push({
                            id: md5.createHash(uuid4.generate()),
                            type: evidence.type,
                            description: '',
                            relatedDocument: {
                                id: '',
                                title: ''
                            }
                        });
                    });
                });
            });
        });
    }

    $scope.isCriteriaActivated = function() {
        if ($scope.criterias && $scope.criterias.length)
            return $scope.criterias.some(el => el.activate === true);
        return false;
    }

    $scope.collectRejectReasons = function() {
        $scope.reject.title = '';
        $scope.rejectReasons.forEach(function(reason) {
            if (reason.checked) {
                if ($scope.reject.title.length) {
                    $scope.reject.title = $scope.reject.title + '; ' + reason.title;
                } else {
                    $scope.reject.title = reason.title;
                }
            }
        });
    };

    $scope.$watch('file', (files) => {
        if (files && files.length) {
            files.forEach(file => {
                if ($scope.files.indexOf(file) === -1) {
                    $scope.files.push(file);
                }
            });
        }
    });

    $scope.deleteDoc = (index) => {
        $scope.files.splice(index, 1);
    };

    $scope.closeModal = function () {
        $uibModalInstance.close();
    };

    $scope.upload = function () {
        // if ( $scope.isAccepted ) {
        if ($scope.files.length > 0) {
            documentsService.uploadFilesList($scope.files, tenderId, {
                    awardId: modalData.awardId
                })
                .then(function (uploadedFiles) {
                    if (uploadedFiles.length !== $scope.files.length) {
                        ntFlash.error(gettext('Некоторые файлы не были загружены. Удалите их и попробуйте их загрузить еще раз'));
                        $scope.requestInProgress = false;
                        return;
                    } else {
                        ntFlash.success(gettext('Файлы загружены. Не забудьте подписать ключом КЕП'));
                        $scope.requestInProgress = false;
                    }
                    $scope.uploadedFiles = uploadedFiles.length;
                    awardsService.getAward(tenderId, modalData.awardId)
                        .then(function (award) {
                            $scope.documents = award.data.response.documents;
                        });
                });
        }
        // }
    };

    $scope.sign = function () {
        $scope.awardSign.signed = false;
        const data = {
            tenderId: tenderId,
            awardId: modalData.awardId,
            documents: $scope.documents || ($scope.documents = []),
            awardSign: $scope.awardSign
        };


        if ($scope.procurementMethodType !== 'reporting' && $scope.procurementMethodType !== 'belowThreshold' ) {
            // console.log($scope.procurementMethodType)

            documentsService.sign(data, {checkPerson : true})
                .then(data => {
                    console.log('documentsService.sign data', data);
                    $scope.awardSign.signed = true;
                })
                .catch(error => console.log('documentsService.sign error', error));

        } else {
            documentsService.sign(data)
                .then(data => {
                    console.log('documentsService.sign data', data);
                    $scope.awardSign.signed = true;
                })
                .catch(error => console.log('documentsService.sign error', error));
        }

    };

    $scope.accept = function () {
        $scope.requestInProgress = true;
        if ($scope.procurementMethodType !== 'aboveThresholdUA' &&
            $scope.procurementMethodType !== 'aboveThresholdUA.defense' &&
            $scope.procurementMethodType !== 'aboveThreshold' &&
            $scope.procurementMethodType !== 'competitiveOrdering' &&
            $scope.procurementMethodType !== 'simple.defense' &&
            $scope.procurementMethodType !== 'competitiveDialogueUA.stage2') {
            delete $scope.agree.eligible;
            delete $scope.agree.qualified;
        }
        console.log($scope.agree)
        awardsService.save({
                tenderId: tenderId,
                awardId: modalData.awardId
            }, $scope.agree)
            .then(function () {
                ntFlash.success(gettext('Предложение принято.'));
                $uibModalInstance.close();
                $state.go('tenderView.auction', null, {
                    reload: true
                });
            });
    };

    $scope.disapprove = function () {
        $scope.requestInProgress = true;

        const data = {};
        if ($scope.isCriteriaActivated()) {
            const requirementResponses = [];
            $scope.criterias.forEach(criteria => {
                if (criteria.activate) {
                    criteria.requirementGroups.forEach(group => {
                        group.requirements.forEach(requirement => {
                            (requirement.requirementResponse.evidences || []).forEach(evidence => {
                                if (evidence.type === 'statement') delete evidence.relatedDocument;
                            });
                            requirementResponses.push(requirement.requirementResponse);
                        });
                    });
                }
            });
            data.requirementResponses = requirementResponses;
        }

        if ($scope.isCancelable) {
            awardsService.save({
                    tenderId: tenderId,
                    awardId: modalData.awardId
                }, { status: 'cancelled', ...data })
                .then(function () {
                    ntFlash.error(gettext('Решение отменено'));
                    $uibModalInstance.close();
                    $state.go('tenderView.auction', null, {
                        reload: true
                    });
                });
        } else {

            $scope.reject.title = $scope.reject.title.toString();
            if (!$scope.reject.title && !$scope.reject.description) {
                delete $scope.reject.title;
                delete $scope.reject.description;
            }
            awardsService.save({
                    tenderId: tenderId,
                    awardId: modalData.awardId
                }, { ...$scope.reject, ...data })
                .then(function () {
                    ntFlash.error(gettext('Предложение отклонено.'));
                    $uibModalInstance.close();
                    $state.go('tenderView.auction', null, {
                        reload: true
                    });
                });
        }

        // if ($scope.isCancelable) {
        //     if ($scope.files.length) {
        //         awardsService.save({tenderId: tenderId, awardId: modalData.awardId}, {status: 'cancelled'})
        //             .then(function() {
        //                 documentsService.uploadFilesList($scope.files, tenderId, {awardId: modalData.awardId})
        //                     .then(function(uploadedFiles) {
        //                         ntFlash.error(gettext('Решение отменено'));
        //                         $uibModalInstance.close();
        //                         $state.go('tenderView.auction', null, {reload: true});
        //                     });
        //             });
        //     } else {
        //         awardsService.save({tenderId: tenderId, awardId: modalData.awardId}, {status: 'cancelled'})
        //             .then(function() {
        //                 ntFlash.error(gettext('Решение отменено'));
        //                 $uibModalInstance.close();
        //                 $state.go('tenderView.auction', null, {reload: true});
        //             });
        //     }
        // } else {
        //     $scope.reject.title = $scope.reject.title.toString();
        //     if(!$scope.reject.title && !$scope.reject.description) {
        //         delete $scope.reject.title;
        //         delete $scope.reject.description;
        //     }
        //     if ($scope.files.length) {
        //         documentsService.uploadFilesList($scope.files, tenderId, {awardId: modalData.awardId})
        //             .then(function(uploadedFiles) {
        //                 if(uploadedFiles.length !== $scope.files.length) {
        //                     ntFlash.error(gettext('Некоторые файлы не были загружены. Удалите их и попробуйте их загрузить еще раз'));
        //                     $scope.requestInProgress = false;
        //                     return;
        //                 }
        //                 awardsService.save({tenderId: tenderId, awardId: modalData.awardId}, $scope.reject)
        //                     .then(function() {
        //                         ntFlash.error(gettext('Предложение отклонено.'));
        //                         $uibModalInstance.close();
        //                         $state.go('tenderView.auction', null, {reload: true});
        //                     });
        //             });
        //     } else {
        //         awardsService.save({tenderId: tenderId, awardId: modalData.awardId}, $scope.reject)
        //             .then(function() {
        //                 ntFlash.error(gettext('Предложение отклонено.'));
        //                 $uibModalInstance.close();
        //                 $state.go('tenderView.auction', null, {reload: true});
        //             });
        //     }
        // }
    };

    $scope.isDisabledBtn = function () {
        return ($scope.awardStatus !== 'active' && $scope.files && $scope.files.length === 0) || $scope.requestInProgress || $scope.preQualificationForm.$invalid;
    };

});
