planningApp.factory('planningService', function(
    http,
    $filter,
    OPC_CONFIG,
    DATETIME_FROMAT,
    ) {

    var createPlanResource = 'plans/';
    var getPlanResource = 'plans/:planId/';
    var deletePlanResource = 'plans/:planId/';
    var editPlanResource = 'plans/:planId/';
    var searchResource = 'plans/?page=:pageNum&q=:searchStr';
    var listResource = 'plans/?page=:pageNum';
    var onlyUserPlanResource = 'plans/?page=:pageNum&user-plans=1';
    var onlyCompanyPlanResource = 'plans/?page=:pageNum&company-plans=1';

    var createMilestones = 'plans/:planId/milestones/';
    var editMilestones = 'plans/:planId/milestones/:milestonesId/';

    var getList = function(page, url) {
        if (!page || page < 1) {
            page = 1;
        }
        var url = url.replace(/:pageNum/g, page);

        return http.get(url)
            .then(function(result) {
                return result;
        });
    };

    var planData = null;

    function plan() {
        this.data = {
            procuringEntity: {
                identifier: {
                    scheme: "",
                    id: "",
                    legalName: ""
                },
                name: "",
                kind: "",
                address: ""
            },
            buyers: [],
            tender: {
                procurementMethod: "open",
                procurementMethodType: "",
                tenderPeriod: {
                    startDate: ""
                }
            },
            items: [],
            classification: {
                scheme: "ДК021",
                description: "",
                id: ""
            },
            additionalClassifications: [],
            budget: {
                project: {
                    name: "",
                    id: ""
                },
                amount: null,
                amountNet: null,
                currency: "",
                id: null,
                notes: "",
                description: "",
                period: {
                    startDate: undefined, // new Date()
                    endDate: undefined,
                },
                breakdown: [
                    {
                        description: "",
                        title: "",
                        value: {
                            amount: null,
                            currency: ""
                        }
                    }
                ]
            }
        };
    }

    var formatData = function(data) {

        var planItems = data.items;

        data.budget.period.endDate.setMonth(11, 31);

        var output = {
            procuringEntity: data.procuringEntity,
            buyers: data.buyers,
            tender: {
                procurementMethod: data.tender.procurementMethod,
                procurementMethodType: data.tender.procurementMethodType,
                tenderPeriod: {
                    startDate: data.tender.tenderPeriod.startDate == null ? null : $filter('date')(data.tender.tenderPeriod.startDate, DATETIME_FROMAT)
                }
            },
            items: [],
            classification: {
                scheme: "ДК021",
                description: data.classification.description,
                id: data.classification.id.toString()
            },
            additionalClassifications: (data.additionalClassifications || []).map((cl) => {
                return {
                    id: cl.id,
                    scheme: cl.scheme,
                    description: cl.description,
                }
            }),
            budget: {
                project: data.budget.project,
                amount: data.budget.amount,
                //"amountNet": data.budget.TaxIncluded ? data.budget.amount / VAT : data.budget.amount,
                currency: data.budget.currency || 'UAH',
                notes: data.budget.notes,
                id: data.classification.id.toString(),
                description: data.budget.description,
                breakdown: data.budget.breakdown
            }
        };

        if (OPC_CONFIG.OPC_CREATE_PLAN_LEGACY) {  // Remove legacy after 20.12.2018
            output.budget.year = data.budget.period.endDate ? $filter('date')(data.budget.period.endDate, 'yyyy') : null

        } else {
            output.budget.period = {
                startDate: data.budget.period.startDate ? $filter('date')(data.budget.period.startDate, DATETIME_FROMAT) : null,
                endDate: data.budget.period.endDate ? $filter('date')(data.budget.period.endDate, DATETIME_FROMAT) : null,
            }
        }

        if (data.tender.procurementMethodType === 'negotiation' || data.tender.procurementMethodType === 'negotiation.quick' || data.tender.procurementMethodType === 'reporting') {
            output.tender.procurementMethod = 'limited';
        }
        if(data.rationale && data.rationale.description !== ''){
            output.rationale = {
                description: data.rationale.description
            };
        }

        if (data.tender.procurementMethodType === 'empty') {
            output.tender.procurementMethod = '';
            output.tender.procurementMethodType = '';
        }

        for (var key in planItems) {
            output.items.push({
                deliveryDate: {
                    endDate: planItems[key].deliveryDate.endDate == null ? null : $filter('date')(planItems[key].deliveryDate.endDate, DATETIME_FROMAT)
                },
                additionalClassifications: (planItems[key].additionalClassifications || []).map((cl) => {
                    return {
                        id: cl.id,
                        scheme: cl.scheme,
                        description: cl.description,
                    }
                }),
                unit: {
                    code: planItems[key].unit.code,
                    name: planItems[key].unit.name
                },
                classification: {
                    scheme: "ДК021",
                    description: planItems[key].classification.description,
                    id: planItems[key].classification.id.toString()
                },
                quantity: planItems[key].quantity,
                description: planItems[key].description
            });
        }

        return output;
    };

    return {
        newPlan: function() {
            planData = new plan().data;
            return planData;
        },
        setPlan: function(plan) {
            planData = plan;
            return plan;
        },
        currentPlan: function() {
            if (planData !== null) {
                return planData;
            } else {
                return new plan().data;
            }
        },
        get: function(id) {
            var planUrl = getPlanResource.replace(/:planId/, id);
            return http.get(planUrl)
                .then(function(result) {
                    return result;
                });
        },
        getAll: function(page) {
            return getList(page, listResource);
        },
        getForUser: function(page) {
            return getList(page, onlyUserPlanResource);
        },
        getForCompany: function(page) {
            return getList(page, onlyCompanyPlanResource);
        },
        save: function(data) {
            return http.post(createPlanResource, formatData(data))
                .then(function(result) {
                    return result;
                });
        },
        create: function(planId) {
            var editUrl = editPlanResource.replace(/:planId/, planId);
            return http.patch(editUrl, {status: 'scheduled'})
                .then(function(data) {
                    return data;
                });
        },
        edit: function(planId, data) {
            var editUrl = editPlanResource.replace(/:planId/, planId);
            return http.put(editUrl, formatData(data))
                .then(function(data) {
                    return data;
                });
        },
        delete: function(planId) {
            var deleteUrl = deletePlanResource.replace(/:planId/, planId);
            return http.delete(deleteUrl)
                .then(function(data) {
                    return data;
                });
        },
        cancel: function(planId, cancellation) {
            var updateUrl = editPlanResource.replace(/:planId/, planId);
            return http.patch(updateUrl, {
                cancellation: cancellation
            });
        },
        addReason: function(planId, rationale) {
            var updateUrl = editPlanResource.replace(/:planId/, planId);
            return http.patch(updateUrl, {
                rationale: {
                    description: rationale
                }
            });
        },
        updatePlanStatus: function(planId, status) {
            var updateUrl = editPlanResource.replace(/:planId/, planId);
            return http.patch(updateUrl, {
                status: status
             });
        },
        search: function(page, data) {
            var resource = searchResource.replace(/:searchStr/g, data.searchStr || '');
            return getList(page, resource);
        },
        createMilestones: function(planId, milestones) {
            var resource = createMilestones.replace(/:planId/g, planId);
            return http.post(resource, milestones)
                .then(function(result) {
                    return result;
                });
        },
        editMilestones: function(planId, milestonesId, reqData) {
            var resource = editMilestones.replace(/:planId/g, planId).replace(/:milestonesId/g, milestonesId);
            return http.patch(resource, reqData)
                .then(function(result) {
                    return result;
                });
        }
    }
});
